import React from 'react'
import { graphql } from 'gatsby'
import { Seo as SEO } from '../../components/Seo'
import Layout from '../../components/layout/Layout'

const PageBanner = React.lazy(() => import ('../../components/common/PageBanner'))
const ProductsServicesDetailSection = React.lazy(() => import ('../../components/productsAndServices/ProductsServicesDetailSection'))

const RegenicPage = ({ data }) => {
  const { post, banner } = data
  const links = [
    { label: 'Home', url: '/' },
    { label: 'Products And Services', url: '/products-and-services' },
    {
      label: post.title,
      url: `/products-and-services/${post.slug}`,
    },
  ]
  return (
    <Layout>
        <PageBanner
          title={"Products And Services"}
          links={links}
          bannerImage={banner.gatsbyImageData}
        />
        <ProductsServicesDetailSection post={post}/>
    </Layout>
  )
}

export default RegenicPage

export const Head = ({ data }) => {
    return (
      <SEO
        title={data.post.title}
        description={data.post.description?.description}
      />
    )
}

export const query = graphql`
  query ($language: String!, $slug: String!, $locale: String) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "products"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    post: contentfulProductsServices(slug: {eq: $slug}, node_locale: {glob: $locale}){
      id
      title
      image{
        gatsbyImageData
      }
      content {
        raw
      }
      description {
        description
      }
      slug
    }
    banner: contentfulAsset(title: { eq: "Product Services Banner" }) {
      gatsbyImageData
    }
  }
`
